import React, { useEffect, useState } from "react";

import GlobalCapterraReviewsCountQuery from "../../assets/StaticQueries/GlobalCapterraReviewsCount.query";
import { Link } from "gatsby";
import ReviewStars from "../Reviews/ReviewStars";
import { Size } from "../../assets/Enums/Size.enum";
import useWindowSize from "../../hooks/useWindowSize";
import HipaaCompliantLight from "../SVGIcons/Hipaa/HipaaCompliantLight";
import HiTrustCertified from "../SVGIcons/Hipaa/HiTrustCertified";
import reviewStarsPng from "../../images/review-stars.png";

import styles from "./Reviews.module.scss";

interface ReviewsProps {
  wrap?: boolean;
  page?: string;
  className?: string;
  isPartner?: boolean;
  isBuilderComponent?: boolean;
}
const Reviews: React.FC<ReviewsProps> = ({
  wrap = false,
  page,
  className = "",
  isPartner = false,
  isBuilderComponent = false,
}) => {
  const reviewsCount = GlobalCapterraReviewsCountQuery();
  const blueBackgroundIcons = ["Partner"];
  const { width } = useWindowSize();
  const [hideIcon, setHideIcon] = useState(false);

  useEffect(() => {
    if (width <= 500) {
      setHideIcon(true);
    } else {
      setHideIcon(false);
    }
  }, [width]);

  const reviewStars = isBuilderComponent ? (
    <img src={reviewStarsPng} height={24} alt="Review stars" />
  ) : (
    <ReviewStars size={Size.SMALL} link={"/reviews/"} />
  );

  return (
    <div
      className={`${
        wrap ? styles.reviewsContainerWithWrap : styles.reviewsContainer
      } ${className} ${isPartner ? styles.partnerContainer : ""}`}
    >
      {reviewStars}

      <span className={styles.reviewsCount}>
        <Link to="/reviews/">{reviewsCount}</Link>
      </span>
      <span
        className={
          blueBackgroundIcons.includes(page)
            ? styles.hipaaCompliantLight
            : styles.hipaaCompliantDark
        }
      >
        <Link to="/features/security/">
          <HipaaCompliantLight />
        </Link>
      </span>
      {page !== "Partner" && (
        <span
          className={`
            ${page === "Home" ? styles.hiTrustLogoPersistent : ""} ${
            page === "Features" ? styles.hiTrustLogo2 : styles.hiTrustLogo
          }`}
        >
          <Link to="/features/security/">
            <HiTrustCertified hideIcon={hideIcon} />
          </Link>
        </span>
      )}
    </div>
  );
};

export default Reviews;
