import * as React from "react";
import { FeatureModuleType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import FeaturesModule from "./FeaturesModule/FeaturesModule";

const FeaturesList: React.FC<{
  featuresList: FeatureModuleType[];
  isPartner?: boolean;
  isPartners?: boolean;
  isCareers?: boolean;
  isCompetitor?: boolean;
}> = ({ featuresList, isPartner, isPartners, isCareers, isCompetitor }) => (
  <div>
    {featuresList.map((feature, index) => (
      <FeaturesModule
        isCareers={isCareers}
        isPartner={isPartner}
        isPartners={isPartners}
        isCompetitor={isCompetitor}
        key={index}
        featureData={feature}
      />
    ))}
  </div>
);

export default FeaturesList;
