import React from "react";

import useModalsTrigger from "../../../sp-state/modals/useModalsTriggerStore";

type ModalTriggerType =
  | "pollen-lead"
  | "resource-gated"
  | "sitewide-generic-gated"
  | "sitewide-generic-resource"
  | "pricing-redirect"
  | "pollen-dm-gated";
interface ModalTriggerProps {
  children: React.ReactNode;
  modalTrigger: ModalTriggerType;
  [key: string]: any;
}
/**
 * `ModalTrigger` with the modal trigger you only get the bare-bones,
 * - @method `handleClick`.
 * - Styles are going to be applied depending on you use case.
 */
const ModalTrigger: React.FC<ModalTriggerProps> = ({
  children,
  modalTrigger,
  ...rest
}) => {
  const { validModalTriggers, setModalTrigger } = useModalsTrigger(
    state => state
  );

  const handleClick = () => {
    if (validModalTriggers.includes(modalTrigger)) {
      return setModalTrigger(modalTrigger);
    }
  };

  return (
    <button data-testid="button:ModalTrigger" onClick={handleClick} {...rest}>
      {children}
    </button>
  );
};

export default ModalTrigger;
