import * as React from "react";
import { FeatureModuleType } from "../../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import BlueLinkWithArrow from "../../Buttons/BlueLinkWithArrow/BlueLinkWithArrow";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useRef } from "react";
import useLazyLoad from "../../../hooks/useLazyLoad";
import setRouteName from "../../../utils/getLocationName";
import styles from "./FeaturesModule.module.scss";

const FeaturesModule: React.FC<{
  featureData: FeatureModuleType;
  isPartner?: boolean;
  isPartners?: boolean;
  isCareers?: boolean;
  isCompetitor?: boolean;
}> = ({
  featureData: {
    featuresModuleAssetToggle,
    featuresModuleItemCta,
    featuresModuleItemCtaText,
    featuresModuleItemImage,
    featuresModuleItemSubcopy,
    featuresModuleItemSubcopySecondary,
    featuresModuleItemTitle,
    featuresModuleItemTitleSecondary,
    featuresModuleItemVideo,
    featuresModuleItemVideoImage,
    featuresModuleItemBadge,
    featuresModuleItemBadgeColor,
  },
  isPartners = false,
  isPartner = false,
  isCareers = false,
  isCompetitor = false,
}) => {
  const image = getImage(featuresModuleItemImage?.localFile);
  const videoRef = useRef<HTMLVideoElement>();
  const location = setRouteName();
  useLazyLoad(videoRef);
  let featureGraphic;
  switch (featuresModuleAssetToggle) {
    case "video":
      featureGraphic = (
        <video
          width="100%"
          autoPlay
          playsInline
          muted
          loop
          ref={videoRef}
          poster={featuresModuleItemVideoImage?.mediaItemUrl}
          className="video"
        >
          <source
            data-src={featuresModuleItemVideo?.mediaItemUrl}
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      );
      break;
    case "image":
      featureGraphic = (
        <GatsbyImage
          image={image}
          alt={featuresModuleItemImage?.altText || ""}
        />
      );
      break;
    default:
      featureGraphic = (
        <GatsbyImage
          image={image}
          alt={featuresModuleItemImage?.altText || ""}
        />
      );
  }

  const featureSubcopyClass = isPartner
    ? `primary--grey-text ${styles.featureSubcopy}`
    : styles.featureSubcopy;

  const featureTitleClass = isPartner
    ? styles.featureTitlePartner
    : styles.featureTitle;

  const modifiedFeatureTitleClass = isCompetitor
    ? styles.featureCompetitorFeatureTitle
    : featureTitleClass;

  const featureCopyPartnersClass = isPartner
    ? styles.featureCopyPartners
    : styles.featureCopyPartnersTemplate;

  const featureCopyClass =
    isPartner || isPartners ? featureCopyPartnersClass : styles.featureCopy;

  const IncubetaData = (text: string) =>
    `data-click-type="download_from_app_store" data-click-location="${location}" data-click-text="${text}"`;
  const appleIconSplitText = `href="https://apps.apple.com/`;
  const playStoreIconSplitText = `href="https://play.google.com/`;

  const moduleItemSubcopyData = featuresModuleItemSubcopy
    .split(appleIconSplitText)
    .join(`${IncubetaData("free for iPhone")} ${appleIconSplitText}`)
    .split(playStoreIconSplitText)
    .join(`${IncubetaData("android")} ${playStoreIconSplitText}`);

  return (
    <div className={`full-width ${styles.featureGrid}`}>
      <div className={featureCopyClass}>
        {featuresModuleItemBadge?.length > 0 && (
          <p
            className={`${styles.featureItemBadge} ${
              isCareers && "badge--text"
            }`}
            style={{ color: featuresModuleItemBadgeColor }}
          >
            {featuresModuleItemBadge}
          </p>
        )}
        <h2
          data-testid="h2:featureTitle"
          className={modifiedFeatureTitleClass}
          dangerouslySetInnerHTML={{ __html: featuresModuleItemTitle }}
        />

        <p
          className={featureSubcopyClass}
          dangerouslySetInnerHTML={{ __html: moduleItemSubcopyData }}
        />
        {featuresModuleItemTitleSecondary && (
          <h2
            data-testid="h2:featureTitle"
            className={modifiedFeatureTitleClass}
            dangerouslySetInnerHTML={{
              __html: featuresModuleItemTitleSecondary,
            }}
          />
        )}
        {featuresModuleItemSubcopySecondary && (
          <p
            className={featureSubcopyClass}
            dangerouslySetInnerHTML={{
              __html: featuresModuleItemSubcopySecondary,
            }}
          />
        )}
        {featuresModuleItemCta && (
          <BlueLinkWithArrow
            link={featuresModuleItemCta}
            linkCopy={featuresModuleItemCtaText}
          />
        )}
      </div>
      <div className={styles.featureImage}>{featureGraphic}</div>
    </div>
  );
};

export default FeaturesModule;
