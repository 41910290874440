import { graphql, useStaticQuery } from "gatsby";

const GlobalCapterraReviewsCountQuery = (): number => {
  const {
    allWp: {
      edges: [
        {
          node: {
            graphqlOptions: {
              globalCapterraReviewCountO: { reviewCount },
            },
          },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query GlobalCapterraReviewCountQuery {
      allWp {
        edges {
          node {
            graphqlOptions {
              globalCapterraReviewCountO {
                reviewCount
              }
            }
          }
        }
      }
    }
  `);
  return reviewCount;
};

export default GlobalCapterraReviewsCountQuery;
