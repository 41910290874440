import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { GroupModuleBannerCType } from "@types";

import styles from "./FeatureGroupBanner.module.scss";

type FeatureGroupBannerType = {
  banner: GroupModuleBannerCType;
  isPartner?: boolean;
  className?: string;
};

const FeatureGroupBanner: React.FC<FeatureGroupBannerType> = ({
  banner,
  isPartner,
  className,
}) => {
  const { copy, iconImage, ctaUrl, ctaText } = banner;
  const enterpriseBannerStyles = isPartner
    ? `${styles.enterpriseBanner}`
    : null;
  const bannerStyles = `${styles.groupBanner}`;

  return (
    <div
      data-testid="div:FeatureGroupBanner"
      className={`${className} ${bannerStyles} ${enterpriseBannerStyles}`}
    >
      {isPartner ? (
        <GatsbyImage
          className={styles.bannerIcon}
          image={iconImage?.gatsbyImage}
          alt={banner?.iconImage?.altText || ""}
        />
      ) : (
        <figure className={styles.groupBannerIcon}>
          <img
            src={banner?.iconImage?.mediaItemUrl}
            alt={banner?.iconImage?.altText}
          />
        </figure>
      )}

      <p className={styles.groupBannerCopy}>
        <span dangerouslySetInnerHTML={{ __html: copy }} />
        &nbsp;
        <Link to={ctaUrl}>{ctaText}</Link>
      </p>
    </div>
  );
};

export default FeatureGroupBanner;
