import * as React from "react";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";
import { Link } from "gatsby";
import { mxpPrimaryCtaClicked } from "../../../utils/mixpanelEvents/mixpanelEvents";
import styles from "./PrimaryButtonWithArrow.module.scss";

type ButtonProps = {
  buttonText: string;
  onClickHandler?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  buttonLink?: string;
  className?: string;
  mailTo?: boolean;
  style?: React.CSSProperties;
  noMxp?: boolean;
  [key: string]: any;
};

const PrimaryButtonWithArrow: React.FC<ButtonProps> = ({
  buttonText,
  onClickHandler,
  buttonLink,
  className,
  mailTo,
  style,
  noMxp = false,
  ...rest
}) => {
  const handleBtnClick = (e: any) => {
    if (!noMxp) mxpPrimaryCtaClicked(e.target.href, e.target.innerText);
    onClickHandler && onClickHandler(e);
  };

  return !buttonLink ? (
    <a
      data-testid="A:PrimaryButtonWithArrow"
      className={`${styles.primaryTrialBtn} ${className}`}
      onClick={handleBtnClick}
      href={"/sign-up/"}
      style={style}
      {...rest}
    >
      {buttonText}
      <RightArrow cls={styles.icon} />
    </a>
  ) : mailTo ? (
    <a
      data-testid="A:PrimaryButtonWithArrow"
      className={`${styles.primaryTrialBtn} ${className}`}
      href={buttonLink}
      style={style}
      {...rest}
    >
      {buttonText}
      <RightArrow cls={styles.icon} />
    </a>
  ) : (
    <Link
      data-testid="A:PrimaryButtonWithArrow"
      className={`${styles.primaryTrialBtn} ${className}`}
      to={buttonLink}
      style={style}
      onClick={handleBtnClick}
      {...rest}
    >
      {buttonText}
      <RightArrow cls={styles.icon} />
    </Link>
  );
};

export default PrimaryButtonWithArrow;
