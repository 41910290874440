import React from "react";
import { Image } from "@types";

import ModalTrigger from "../../Buttons/ModalTrigger";
import RightArrow from "../../SVGIcons/RightArrowSmall";

import articleCtaStyles from "./ArticleCta.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

export interface ArticleCtaProps {
  articleCtaImage: Image;
  articleCtaMainCopy: string;
  articleCopyText: string;
  gated?: boolean | null;
  articleCtaLink: string | null;
  articleCtaText: string | null;
  isPartner?: boolean;
  className: string;
}

const ArticleCtaV2: React.FC<ArticleCtaProps> = ({
  articleCtaImage,
  articleCtaMainCopy,
  articleCopyText,
  gated,
  articleCtaText,
  articleCtaLink,
  className,
  isPartner,
}) => {
  return (
    <>
      <div
        className={`${articleCtaStyles.articleCtaContainer} ${className} ${
          isPartner && articleCtaStyles.gatedBanner
        }`}
        data-testid="article-cta"
      >
        <div className={articleCtaStyles.articleCtaBackdrop}></div>
        <div className={articleCtaStyles.articleCta}>
          <GatsbyImage
            className={articleCtaStyles.imageStyles}
            data-testid="article-cta-image"
            alt={articleCtaImage?.altText}
            image={articleCtaImage.gatsbyImage}
          />

          <div className={articleCtaStyles.articleCtaCopy}>
            <h3
              className={articleCtaStyles.articleCtaHeadline}
              data-testid="article-cta-main-copy"
            >
              {articleCtaMainCopy}
            </h3>
            {articleCopyText && <p>{articleCopyText}</p>}
            {gated ? (
              <ModalTrigger
                modalTrigger="resource-gated"
                data-testid="article-cta-btn"
              >
                Download now
                <RightArrow />
              </ModalTrigger>
            ) : (
              <a
                data-testid="article-cta-btn"
                aria-label="Download Free"
                href={articleCtaLink}
                download
              >
                {articleCtaText}
                <RightArrow />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCtaV2;
