import { useEffect } from "react";
import { Cookie } from "../utils/Cookie";

/**
* @summary **Capture user's initial intent on sign up**: https://www.pivotaltracker.com/story/show/182018813
* @description A ``userIntent`` or ``competitorUserIntent`` cookie will be dropped on the user's device when landing on this page. **If already has either one of these cookies on their browser, a new one should not be dropped.** **If an ACF field for either of these is empty, the cookie should not be dropped.**

- **``User Intent``**
- **Templates**:
- - Features Template
- - Partner Template
- - FAQ Template
- - Switching Template
- **``Competitor User Intent``**
- **Templates**:
- - VS Template
- - Competitor Template
 */

const useOnUserIntent = (data: Queries.WpFeaturesTemplate_Userintent) => {
  useEffect(() => {
    if (data !== null) {
      if (!data.cookieName || !Cookie.getCookie(data.cookieName)) {
        Cookie.setCookie(data.cookieName, data.cookieValue, 365);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
};

export default useOnUserIntent;
