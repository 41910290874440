import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { IPartnersTemplateData } from "@types";
import MainLayout from "../../layouts/main";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import PartnerIndividualBanner from "../../components/Banners/PartnerIndividualBanner/PartnerIndividualBanner";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useSetPromocode from "../../hooks/useSetPromocode";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import FeatureGroupBanner from "../../components/FeatureGroupBanner/FeatureGroupBanner";
import ArticleCtaV2 from "../../components/Shortcodes/CTAs/ArticleCtaV2";
import { deIndexSeo } from "../../utils/deIndexSeo";

const PartnerPage: React.FC<PageProps<IPartnersTemplateData>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: {
      globalFooterLargeCtaO,
      heroSingleP,
      featuresModuleP,
      testimonialSliderP,
      groupModuleBannerC,
      enterpriseGatedBanner,
      partnerPromoPg: { partnerPromo },
      isPartnerCookie: { isPartner },
      userIntent,
    },
  } = wpPage;

  useSetPromocode(partnerPromo, isPartner);
  useOnUserIntent(userIntent);

  return (
    <MainLayout
      FooterLargeCtaData={globalFooterLargeCtaO}
      hasFooterLargeCta={true}
      backgroundColor="blue"
    >
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <PartnerIndividualBanner
        heroSingleSubcopy={heroSingleP.heroSingleSubcopy}
        heroSingleTitle={heroSingleP.heroSingleTitle}
        partnerLogo={heroSingleP.partnerLogo}
        heroSingleLinkText={heroSingleP.heroSingleLinkText}
        heroSingleLink={heroSingleP.heroSingleLink}
      />
      <FeaturesModulePContainer
        props={featuresModuleP}
        page={featuresModuleP.featuresModuleTitle}
        isPartner={true}
        withPaddingB={false}
      />
      {enterpriseGatedBanner.mainCopy &&
        enterpriseGatedBanner.addGatedBanner && (
          <ArticleCtaV2
            className="full-width"
            gated={enterpriseGatedBanner.gated}
            articleCtaImage={enterpriseGatedBanner.image}
            articleCtaMainCopy={enterpriseGatedBanner.mainCopy}
            articleCopyText={enterpriseGatedBanner.copyText}
            articleCtaLink={enterpriseGatedBanner.articleCtaLink}
            articleCtaText={enterpriseGatedBanner.articleCtaText}
            isPartner={true}
          />
        )}
      <TestimonialSliderPContainer
        props={testimonialSliderP}
        isPartner={true}
      />
      {groupModuleBannerC.addGroupBanner && (
        <FeatureGroupBanner
          banner={groupModuleBannerC}
          className="full-width"
          isPartner={true}
        />
      )}
    </MainLayout>
  );
};

export default PartnerPage;

export const query = graphql`
  query PartnerTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...GetPartnerTemplateInfo
    }
  }
`;
