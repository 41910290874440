import { useEffect } from "react";
import { Cookie } from "../utils/Cookie";

const useSetPromocode = (promocode: string, isPartner?: boolean) => {
  useEffect(() => {
    if (!promocode) return;
    Cookie.deleteCookieFromBaseDomain("PROMOCODE");
    Cookie.setCookie("PROMOCODE", promocode, 365);

    if (isPartner) {
      Cookie.deleteCookieFromBaseDomain("IS_PARTNER");
      Cookie.setCookie("IS_PARTNER", true, 365);
    }
  }, []);
};

export default useSetPromocode;
