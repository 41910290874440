import * as React from "react";

const RightArrowSmall: React.FC<{ [key: string]: any }> = ({ ...props }) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 -3.0598e-07L5.76625 1.23375L10.6488 6.125L-2.67732e-07 6.125L-3.44227e-07 7.875L10.6487 7.875L5.7575 12.7575L7 14L14 7L7 -3.0598e-07Z"
      fill="#1371C8"
    ></path>
  </svg>
);

export default RightArrowSmall;
