import { useEffect, MutableRefObject } from "react";

const IntersectionOberserverCB = (entries, _, lazyVideoObserver) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      for (let source in entry.target.children) {
        const videoSrc = entry.target.children[source] as HTMLSourceElement;
        if (
          typeof videoSrc.tagName === "string" &&
          videoSrc.tagName === "SOURCE"
        ) {
          videoSrc.src = videoSrc.dataset.src;
        }
      }
      (entry.target as HTMLVideoElement).load();
      (entry.target as HTMLVideoElement).classList.remove("lazy");
      lazyVideoObserver.unobserve(entry.target);
    }
  });
};

const useLazyLoad = (reference: MutableRefObject<HTMLVideoElement>) => {
  useEffect(() => {
    if ("IntersectionObserver" in window && reference.current !== undefined) {
      const lazyVideoObserver = new IntersectionObserver((entries, observer) =>
        IntersectionOberserverCB(entries, observer, lazyVideoObserver)
      );
      lazyVideoObserver.observe(reference.current);
    }
  });
};

export default useLazyLoad;
