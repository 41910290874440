import * as React from "react";
import { HeroSinglePProps } from "@types";
import Reviews from "../../Reviews/Reviews";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PrimaryButtonWithArrow from "../../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import styles from "./PartnerIndividualBanner.module.scss";

const PartnerIndividualBanner: React.FC<HeroSinglePProps> = ({
  heroSingleSubcopy,
  heroSingleTitle,
  partnerLogo,
  heroSingleLinkText,
  heroSingleLink,
}) => {
  return (
    <div className={`backgroundBlue ${styles.bannerContainer}`}>
      <div className={`full-width shorter`}>
        <div className={`text-alignment ${styles.partnerStyle}`}>
          {partnerLogo ? (
            <GatsbyImage
              image={getImage(partnerLogo.localFile)}
              alt={heroSingleTitle || ""}
            />
          ) : (
            <img data-src="#" alt="NoImage" src="#" data-loaded="true" />
          )}
          <h1
            data-testid="H1:title"
            className={styles.bannerTitle}
            dangerouslySetInnerHTML={{ __html: heroSingleTitle }}
          />

          <p className={styles.bannerSubTitle}>{heroSingleSubcopy}</p>

          <PrimaryButtonWithArrow
            buttonText={
              heroSingleLinkText ? heroSingleLinkText : "Start my free trial"
            }
            data-click-type="sign_up_cta"
            data-click-text="Start for free"
            data-click-location="Partner-Individual-Banner"
            buttonLink={heroSingleLink ? heroSingleLink : "/sign-up/"}
          />

          <Reviews
            isPartner={true}
            className={styles.reviewStyle}
            page="Partner"
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerIndividualBanner;
