import * as React from "react";
import { FeaturesModulePType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import FeaturesList from "../../components/Features/FeaturesList";
import { hasPadding } from "../../utils/hasPadding";
import styles from "./FeaturesModulePContainer.module.scss";

type FeaturesModulePContainerProps = {
  props: FeaturesModulePType;
  page?: string;
  withPaddingB?: boolean;
  withPaddingT?: boolean;
  className?: string;
  hasTitle?: boolean;
  isPartner?: boolean;
  isPartners?: boolean;
  isCareers?: boolean;
  isCompetitor?: boolean;
  isGreyBg?: boolean;
};

const FeaturesModulePContainer: React.FC<FeaturesModulePContainerProps> = ({
  props: { featuresModuleList, featuresModuleTitle, featuresModuleSubcopy },
  page = "",
  withPaddingB = true,
  withPaddingT = true,
  className = "",
  hasTitle = true,
  isPartner = false,
  isPartners = false,
  isCareers = false,
  isCompetitor = false,
  isGreyBg = false,
}) => {
  const sectionClassName = ` ${isGreyBg ? styles.featuresSectionBg : ""}
    ${className} ${withPaddingB ? "pad-bottom--large" : " "}
    ${withPaddingT ? "pad-top--large" : " "}
    ${isPartner && styles.paddingBottomMedium}
    ${hasPadding(page) ? styles.featuresContainer : ""}`;

  const titleClassName = `full-width
    ${styles.featuresTitle}
    ${featuresModuleSubcopy?.length > 0 ? styles.featuresTitleSubCopy : ""}`;

  return (
    <section
      data-testid="section:featuresContainer"
      className={sectionClassName}
    >
      {hasTitle && featuresModuleTitle?.length > 0 && (
        <h2 className={titleClassName}>{featuresModuleTitle}</h2>
      )}
      {featuresModuleSubcopy?.length > 0 && (
        <p className={`full-width shorter ${styles.featuresSubCopy}`}>
          {featuresModuleSubcopy}
        </p>
      )}
      <FeaturesList
        isCareers={isCareers}
        isPartner={isPartner}
        isPartners={isPartners}
        isCompetitor={isCompetitor}
        featuresList={featuresModuleList}
      />
    </section>
  );
};

export default FeaturesModulePContainer;
