import * as React from "react";
import { Link } from "gatsby";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";
import styles from "./LinkWithArrow.module.scss";

type BlueLinkWithArrowProps = {
  link: string;
  linkCopy: string;
  arrowDirection?: string;
  className?: string;
};

const BlueLinkWithArrow: React.FC<BlueLinkWithArrowProps> = ({
  link,
  linkCopy,
  arrowDirection,
  className = "",
}) => (
  <p
    data-testid="P:BlueLinkWithArrow"
    className={`${styles.linkContainer} ${className}`}
  >
    <Link to={link} className={styles.link}>
      <span className={styles.linkText}>{linkCopy}</span>
      <span className={styles.arrow}>
        {arrowDirection === "up" ? (
          <RightArrow cls={styles.iconUp} />
        ) : (
          <RightArrow cls={styles.icon} />
        )}
      </span>
    </Link>
  </p>
);

export default BlueLinkWithArrow;
